import { dateFn } from './dateTimeFormatter';
import { titleCaseFormatter } from './stringFormatters';

const phone_mask_US_format = (value) => {
  if (!value) return '';
  value = value?.replace('+1', '');
  var x = value?.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  x = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
  let string = `+1${x}`;
  return {
    formatted: value?.length <= 0 ? value : string ?? '',
    unformatted: value?.length <= 2 ? value : string?.replace(/([()-\s])/g, '') ?? '',
  };
};

const phone_mask_US_string = (value) => {
  let _value = value?.replace(/[^+,0-9]/g, '');
  if (_value?.length > 12) {
    _value = _value.slice(0, -1);
    return _value;
  } else if (_value?.length == 12) {
    return _value;
  }
};

const phone_add_plus_one = (value) => {
  if (!value) return '';
  value = value?.replace('+1', '');
  let string = `+1${value}`;
  return string;
};

const phone_remove_plus_one = (value) => {
  if (!value) return '';
  value = value?.replace('+1', '');
  return value;
};
const numberConverter = (value) => {
  if (typeof value === 'string') {
    value = Number(value);
  }
  if (typeof value === 'number') {
    if (Number.isInteger(value)) {
      if (value >= 0) {
        return value;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
};
const amountCheckFn = (val) => {
  const value = +val;
  if (isNaN(value)) {
    return '-';
  }
  switch (value) {
    case 0:
      return '$0.00';
    case null:
      return '-';
    case undefined:
      return '-';
    default:
      return `$${value?.toFixed(2)}`;
  }
};
const textCheckFn = (val) => {
  let value = val;
  switch (value) {
    case '':
      return '-';
    case null:
      return '';
    case undefined:
      return '-';
    default:
      return titleCaseFormatter(value);
  }
};
const numberCheckFn = (val) => {
  let value = val;
  switch (value) {
    case '':
      return '-';
    case null:
      return '-';
    case undefined:
      return '-';
    default:
      return value;
  }
};

const dollarAmountCheckFn = (value) => {
  if (value === 0) {
    return '$.00';
  } else {
    return `$${value}`;
  }
};

const accountTypeFormatFn = (type) => {
  switch (type) {
    case 'INDIVIDUAL':
      return 'Individual';
    case 'ORGANIZATION':
      return 'Organization';
    case 'ORGANIZATION_MEMBER':
      return 'Member';
    default:
      return '-';
  }
};

const timeIdGenarate = (date, time) => {
  return `${date}${time}`.replace(/[:|-]/g, '');
};

const sortTimeSlots = (timeSlots) => {
  function convertTo24HourTime(time) {
    const matches = time.match(/(\d+)( AM| PM)/);
    let hours = parseInt(matches[1], 10);
    const modifier = matches[2];

    if (modifier === ' PM' && hours < 12) {
      hours += 12;
    }
    if (modifier === ' AM' && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, '0')}:00`;
  }

  timeSlots.forEach((slot) => {
    const startTime = slot.value.split(' - ')[0];
    slot.sortKey = convertTo24HourTime(startTime);
  });

  return timeSlots.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
};
const formatAndSortStartTimes = (data) => {
  Object.keys(data).forEach((date) => {
    const formattedSlots = data[date].map((obj) => {
      let tempTime = dateFn('toLocalOnlyTime', date, obj.start_time);
      obj.localTime = tempTime.replace(/(:00|:30)/g, '');
      return obj;
    });

    data[date] = sortTimeObjects(formattedSlots);
  });
  return data;
};

const sortTimeObjects = (timeSlots) => {
  function convertTo24HourTime(time) {
    const matches = time.match(/(\d+)(:\d+)?( AM| PM)/);
    let [hours, minutes] = matches[1].split(':');
    hours = parseInt(hours, 10);
    minutes = minutes || '00'; // Default minutes to '00' if not provided
    const modifier = matches[3];

    if (modifier === ' PM' && hours < 12) {
      hours += 12;
    }
    if (modifier === ' AM' && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  }

  timeSlots.forEach((slot) => {
    const startTime = slot.localTime; // Use the formatted localTime for sorting
    slot.sortKey = convertTo24HourTime(startTime);
  });

  return timeSlots.sort((a, b) => a.sortKey.localeCompare(b.sortKey));
};
const subTypeText = (type) => {
  type = type?.toLowerCase();
  switch (type) {
    case 'week':
      return 'weekly';
    case 'month':
      return 'monthly';
    case 'biweekly':
      return 'biweekly';
    default:
      return '-';
  }
};
export {
  phone_mask_US_format,
  phone_mask_US_string,
  phone_add_plus_one,
  phone_remove_plus_one,
  numberConverter,
  amountCheckFn,
  textCheckFn,
  numberCheckFn,
  dollarAmountCheckFn,
  accountTypeFormatFn,
  timeIdGenarate,
  sortTimeSlots,
  formatAndSortStartTimes,
  subTypeText,
};
