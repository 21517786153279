import React, { useState } from 'react';
import RatingStars from './RatingStars';
import no_picture from '../images/no-profile-picture.png';
import rising_star from '../../src/icons/rising-star.svg';
export default function ProviderProfile({ provider, type = 'single', readonly = false }) {
  const [ratingValue, setRatingValue] = useState(provider?.rating);
  const handleRating = () => {
    setRatingValue(rate);
  };
  return (
    <div className={'mb-2 items-center ' + (type === 'single' ? ' flex min-h-[80px]' : ' min-h-[180px] flex-row')}>
      <div className={'flex ' + (type === 'single' ? 'w-1/2 justify-end' : 'w-full justify-center')}>
        <img
          className={' h-[80px] w-[80px] rounded-full ' + (type === 'single' ? 'mr-4' : 'mb-4')}
          src={provider?.profile_image ? provider?.profile_image.absolute_url : no_picture}
          alt={provider?.first_name}
        />
      </div>
      <div className={' ' + (type === 'single' ? 'w-1/2' : 'w-full text-center')}>
        <div className='go-blue-20 mb-1'>
          {provider?.first_name} {provider?.last_name}
        </div>
        {provider?.delivered_orders == 1 ? (
          <div className='go-textblack-15 mb-1'>{provider?.delivered_orders} Order Delivered</div>
        ) : provider?.delivered_orders > 1 ? (
          <div className='go-textblack-15 mb-1'>{provider?.delivered_orders} Orders Delivered</div>
        ) : (
          <div className='go-textblack-15 text-client-300 mb-1 flex justify-center'>
            <img src={rising_star} alt='tick' className='mr-2 h-5 w-5' />
            <span>Rising Talent</span>
          </div>
        )}
        {provider?.average_ratings > 0 && provider?.delivered_orders > 0 ? (
          <RatingStars onClick={() => handleRating} ratingValue={provider?.average_ratings} readonly={readonly} />
        ) : provider?.average_ratings == 0 && provider?.delivered_orders > 0 ? (
          <div className='go-textblack-15 text-client-300 mb-1'>Be the first to Review</div>
        ) : (
          <div className='go-textblack-15 mb-1'>Ready for Deliveries</div>
        )}
      </div>
    </div>
  );
}
