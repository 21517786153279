//convert string to lowercase and replace space with dash
const toLowerCaseAndReplaceDash = (str) => {
  return str.toLowerCase().replace(/\s/g, '-');
};

const ordinal_suffix_of = (i) => {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export { toLowerCaseAndReplaceDash, ordinal_suffix_of, capitalizeFirstLetter };
