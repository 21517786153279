import React, { useCallback, useEffect, useState } from 'react';
import Modal from '../../../components/Modal';
import NewServiceAddressModal from './NewServiceAddressModal';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedServiceAddressAction } from '../../../redux/Actions/ServiceAddressesActions';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
import { getOrderPreferencesApi } from '../../../redux/Reducers/MyAccountReducer';
import toast from 'react-hot-toast';

export default function PickupAddress() {
  const dispatch = useDispatch();
  const { allServiceAddresses, selectedAddress } = useSelector((state) => state.serviceAddresses);
  const { orderServiceAddress } = useSelector((state) => state.createNewOrder);
  const { profileData } = useSelector((state) => state.profile);
  const [newAddressModalOpen, setNewAddressModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const setSelectedAddressFn = useCallback(
    (address) => {
      setLoading(true);
      dispatch(setSelectedServiceAddressAction(address));
      dispatch(setOrderDataAction({ selectedServices: [] }));
      const preferences = dispatch(getOrderPreferencesApi(address?.id));
      preferences?.then((res) => {
        if (res?.status === 200 || res?.status === 201) {
          setLoading(false);
          dispatch(
            setOrderDataAction({
              orderServiceAddress: address || res?.data?.preferred_service_address,
            })
          );
          dispatch(
            setOrderDataAction({
              orderProvider: res?.data?.preferred_worker || null,
            })
          );
        } else {
          setLoading(false);
          toast.error(res?.message || 'Something went wrong');
        }
      });
    },
    [selectedAddress]
  );

  useEffect(() => {
    !orderServiceAddress && setSelectedAddressFn(selectedAddress);
  }, [selectedAddress]);

  return (
    <>
      <div>
        <div className={'mt-10 grid grid-cols-12 gap-6 ' + (loading && ' opacity-50 ')}>
          {allServiceAddresses?.map((address, i) => (
            <div
              className={
                '  col-span-full cursor-pointer rounded-lg  border-2  bg-white xl:col-span-4' +
                (orderServiceAddress
                  ? orderServiceAddress?.id === address?.id
                    ? ' border-client-link '
                    : ' border-white'
                  : selectedAddress?.id === address?.id
                  ? ' border-client-link '
                  : ' border-white') +
                (!address?.is_address_in_active_service_area
                  ? ' pointer-events-none bg-gray-200 opacity-50'
                  : 'bg-white')
              }
              onClick={() =>
                setSelectedAddressFn({ id: address?.id, full_address: address?.full_address, name: address?.name })
              }
              key={i}
            >
              <div className='flex h-52 flex-col p-5'>
                <div className='relative grow'>
                  <div className='mb-0.5 items-start justify-between sm:flex'>
                    <div className='mb-2 items-center sm:mb-0 xl:flex'>
                      <h3 className=' go-label-20 pb-2'>{address?.name}</h3>
                    </div>
                  </div>
                  <div className='go-heading-20 mb-2'>{address?.full_address}</div>
                  {!address?.is_address_in_active_service_area && (
                    <span className=' right-1 top-1 rounded-lg bg-red-600 px-3 py-1 text-white'>
                      Service not available at this time.
                    </span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className='my-4 flex cursor-pointer'
        onClick={(e) => {
          e.stopPropagation();
          setNewAddressModalOpen(!newAddressModalOpen);
        }}
      >
        {profileData?.is_allowed_to_add_address && (
          <div className='flex items-center'>
            <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
              <g fill='#001C5C' fillRule='nonzero'>
                <path d='M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16z' />
                <path d='M15 11h-2V9a1 1 0 0 0-2 0v2H9a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2z' />
              </g>
            </svg>
            <a className='go-blue-16 ml-2' href='#'>
              Add new service address
            </a>
          </div>
        )}
      </div>
      <Modal
        id='new-address-modal'
        modalOpen={newAddressModalOpen}
        setModalOpen={setNewAddressModalOpen}
        title='Add new service address'
      >
        <NewServiceAddressModal modalOpen={newAddressModalOpen} setModalOpen={setNewAddressModalOpen} />
      </Modal>
    </>
  );
}
