import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { forgotPasswordApi } from '../../redux/Reducers/commonApis';
import Loader from '../../components/Loader';
function ForgotPasswordPage({ setType }) {
  const [loading, setLoading] = useState(false);
  const [resetType, setResetType] = useState('notsent');
  const [resetMsg, setResetMsg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data, e) => {
    setLoading(true);
    const result = forgotPasswordApi(data);
    result?.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setLoading(false);
        setResetType('sent');
        setResetMsg(res?.data);
        // const getProfileData = dispatch(getProfileDataApi(true));
        // getProfileData?.then((res) => {
        //   if (res?.status === 200 || res?.status === 201) {
        //     setLoading(false);
        //     dispatch(getAllServiceAddressesApi());
        //     if (localStorage.getItem('lastUrl')) {
        //       navigate(localStorage.getItem('lastUrl'));
        //       localStorage.removeItem('lastUrl');
        //     } else {
        //       navigate('/dashboard');
        //     }
        //   }
        // });
      } else {
        setLoading(false);
        toast.error(res?.message || 'Invalid Credentials');
      }
    });
  };

  return loading ? (
    <div className='mx-auto  w-1/4 px-4 py-8'>
      <Loader text='processing...' classes='h-36 w-80 bg-slate-100 rounded-lg mb-2 ' />
    </div>
  ) : resetType === 'notsent' ? (
    <div className='mx-auto  w-1/4 px-4 py-8'>
      <h1 className='bg-gr h- w- mb-6 text-center text-3xl font-bold text-slate-800'>Reset password</h1>
      <form className='mb-6'>
        <div className='space-y-8'>
          <div>
            <label className='go-label-15' htmlFor='email'>
              Email
            </label>
            <input
              id='email'
              className={`go-inputtext-15 w-full ${errors?.email?.type === 'required' ? 'border-red-500' : ''}`}
              placeholder='Enter your email'
              type='email'
              name='email'
              required
              {...register('email', {
                required: {
                  value: true,
                  message: 'Please enter your email address',
                },
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Invalid email address',
                },
              })}
              autoComplete='off'
            />
            {errors.email?.message && <p className='go-textnormal-14 text-red-500'>{errors.email?.message}</p>}
          </div>
        </div>
      </form>
      <div className='mb-2 border-slate-200 pb-2'>
        <div className='flex items-center justify-between space-x-4'>
          <Link
            className='w-full rounded border border-[#548ff7] py-2 px-4 text-center text-xs font-semibold text-[#548ff7]'
            onClick={(e) => {
              e.preventDefault();
              setType('signin');
            }}
            to='#'
          >
            Cancel
          </Link>
          <Link
            className='w-full rounded border border-[#548ff7] bg-[#548ff7] py-2 px-4 text-center text-xs font-semibold text-white'
            onClick={(e) => {
              handleSubmit(onSubmit)(e);
            }}
            to='#'
          >
            Send
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className='mx-auto  w-2/4 px-4 py-8 text-center'>
      <h1 className='bg-gr h- w- mb-6 text-3xl font-bold text-slate-800'>{resetMsg}</h1>
      <div className='mb-2 border-slate-200 pb-2 font-semibold'>
        <p>
          Didn't receive an email?{' '}
          <Link
            className='text-[#548ff7]'
            to='#'
            onClick={(e) => {
              handleSubmit(onSubmit)(e);
            }}
          >
            Send Again.
          </Link>
        </p>
      </div>
    </div>
  );
}
export default ForgotPasswordPage;
