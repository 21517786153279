import React, { useEffect, useState } from 'react';
import ModalWithHeading from '../../../components/ModalWithHeading';
import ClientButton from '../../../components/ClientButton';
import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
export default function AgreementModal({ agreementModal, setAgreementModal, onacceptclick }) {
  const tenant = import.meta.env.VITE_TENANT_NAME;
  const [agreements, setAgreements] = useState([
    {
      id: 1,
      title: `I will package my laundry in 13-gallon (non-stretch) trash bags or Laundry Care’s reusable bags.`,
      show: tenant === 'pushlaundry' ? false : true,
    },
    {
      id: 2,
      title: `I understand that if I package my laundry in a different bag it will be measured after pick up in 13-gallon sized bags for accurate pricing.`,
      show: tenant === 'pushlaundry' ? false : true,
    },
    {
      id: 3,
      title: `I understand my laundry will be weighed before it has been washed and dried.`,
      show: tenant === 'pushlaundry' ? true : false,
    },
    {
      id: 4,
      title: `I understand that stain removal is not guaranteed.`,
      show: true,
    },
    {
      id: 5,
      title: `I agree to not include contaminated items (bugs, blood, urine or feces)with order.`,
      show: true,
    },
    {
      id: 6,
      title: `I understand that all items will be washed in a washer and dried in a dryer (we do not offer dry cleaning).`,
      show: true,
    },
    {
      id: 7,
      title: `I understand that items I’ve asked to be air-dried may be damp upon delivery.`,
      show: true,
    },
    {
      id: 8,
      title: `I agree to the terms of services outlined here.`,
      show: true,
    },
  ]);
  const onCheckboxChange = (e, id) => {
    const newAgreements = agreements.map((agreement) => {
      if (agreement?.id === id) {
        agreement.selected = e.target.checked;
      }
      return agreement;
    });
    setAgreements(newAgreements);
  };

  return (
    <ModalWithHeading
      modalOpen={agreementModal}
      setModalOpen={setAgreementModal}
      title='Service Agreements'
      classes='max-w-3xl'
    >
      <div className='mx-8'>
        <div className='flex-row items-start '>
          {agreements?.map(
            (agreement, index) =>
              agreement?.show && (
                <div className='my-6 ' key={index}>
                  <div className={'flex '}>
                    {/* <input
                  type='checkbox'
                  className='form-checkbox mt-1'
                  onChange={(e) => onCheckboxChange(e, agreement?.id)}
                /> */}
                    <span className='w-5'>
                      <ChevronRightIcon className='h-5 w-5 text-blue-500' />
                    </span>
                    {agreement?.id === 8 ? (
                      <span className='go-textblackmedium-16 ml-2'>
                        I agree to the terms of services outlined{' '}
                        <a href='/tos' className='text-blue-500 '>
                          here.
                        </a>
                      </span>
                    ) : (
                      <span className={`go-textblackmedium-16 ml-2`}>{agreement?.title}</span>
                    )}
                  </div>
                </div>
              )
          )}
        </div>

        <div className='flex justify-center py-5'>
          <ClientButton
            variant='secondary'
            text='Deny'
            classes=''
            onClick={(e) => {
              setAgreementModal(false);
            }}
          />
          <ClientButton
            variant='primary'
            text='Accept'
            // disabled={agreements?.filter((agreement) => agreement?.selected).length < 7}
            classes=''
            onClick={(e) => {
              onacceptclick(e, true);
            }}
          />
        </div>
      </div>
    </ModalWithHeading>
  );
}
