import { GET_PROFILE_DATA, PROFILE_LOADING } from '../ActionsTypes';
import { getRequest, postRequest } from '../../utils/axios';
import toast from 'react-hot-toast';
const initialState = {
  profileData: null,
  profileLoading: false,
};
export const PaymentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PROFILE_DATA:
      return { ...state, profileData: payload };
    case PROFILE_LOADING:
      return { ...state, profileLoading: payload };

    default:
      return state;
  }
};

export const getMyInvoicesApi = async (filters) => {
  try {
    const response_data = await postRequest(`/transactions/client/invoices`, filters);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};
export const getInvoiceFiltersApi = async (data) => {
  try {
    const response_data = await postRequest(`/transactions/client/invoices/filters`, data);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};

export const getInvoiceDetailsbyID = async (id) => {
  try {
    const response_data = await getRequest(`/transactions/client/invoices/` + id);
    return response_data;
  } catch (error) {
    toast.error(error?.response?.data?.error?.message || 'Something went wrong');
    return error?.response?.data;
  }
};

