/* eslint-disable react/prop-types */
import React from 'react';

export default function ClientButton({
  variant,
  size = 'normal',
  text,
  onClick,
  classes,
  disabled = false,
  type = 'button',
}) {
  const classSwitch = (key) => {
    switch (key) {
      case 'primary':
        return 'bg-client-text text-white ';
      case 'secondary':
        return 'hover:bg-portal-100 text-client-text border-client-text border-1 bg-white ';
      case 'link':
        return 'text-client-link bg-white border-client-link  ';
      case 'selected-link':
        return 'text-white bg-client-link border-client-link  ';
      case 'cancel':
        return 'text-white bg-client-cancelled border-client-cancelled  ';
      default:
        break;
    }
  };
  const customClass = (key) => {
    switch (key) {
      case 'large':
        return 'min-w-max py-3 px-4 text-[16px] sm:py-4 sm:px-4 sm:text-[18px] ';
      case 'normal':
        return 'min-w-30 sm:min-w-60 py-2 px-4 text-[14px] sm:py-3 sm:px-4 sm:text-[16px] ';
      case 'small':
        return 'min-w-30 sm:min-w-60 py-1.5 px-4 text-[14px] sm:py-2 sm:px-4 sm:text-[16px]  ';
      case 'xs':
        return 'min-w-36 py-0.5 px-1 text-[12px] sm:py-1 sm:px-1 sm:text-[14px] ';
      default:
        break;
    }
  };
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        'btn relative z-10 my-1 mx-2 rounded-full disabled:cursor-not-allowed   ' +
        (disabled ? 'opacity-50 ' : '') +
        customClass(size) +
        classSwitch(variant) +
        classes
      }
      type={type}
    >
      {/* {text.replace(/\b\w/g, (l) => l.toUpperCase())} */}
      {text}
    </button>
  );
}
