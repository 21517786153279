import React, { useCallback, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
import { getAllProvidersApi } from '../../../redux/Reducers/ProvidersReducer';
import Slider from 'react-slick';
import SingleProvider from './SingleProvider';
import Loader from '../../../components/Loader';
import ClientButton from '../../../components/ClientButton';
import { isEmpty } from 'lodash';

export default function SelectProvider({ setstep }) {
  const dispatch = useDispatch();
  const { orderProvider, orderServiceAddress, selectedServices } = useSelector((state) => state.createNewOrder);
  const { orderPreferences } = useSelector((state) => state.myaccount);
  const { allProviders, allProvidersLoading } = useSelector((state) => state.providers);
  const [providersList, setProvidersList] = useState([]);
  const onSelectProvider = useCallback(
    (provider) => {
      dispatch(setOrderDataAction({ orderProvider: provider === null ? null : provider, orderTimeSlots: {} }));
    },
    [dispatch]
  );

  const providerListToDisplayFn = useCallback(
    (providers) => {
      const newList = providers?.map((provider) => {
        if (provider?.id === orderPreferences?.preferred_worker?.id) {
          return { ...provider, isPreferred: true };
        }
        return provider;
      });
      const index = newList?.findIndex((item) => item?.isPreferred);
      const preferredProvider = newList?.find((item) => item?.isPreferred);
      const temp = [...newList];
      if (preferredProvider) {
        temp.splice(index, 1);
        temp.unshift(preferredProvider);
      }
      temp.push({});

      setProvidersList(temp);
    },
    [orderPreferences]
  );

  useEffect(() => {
    const temp = {
      service_ids: selectedServices?.map((service) => service?.service_id) || [],
    };
    dispatch(getAllProvidersApi(orderServiceAddress?.id, temp));
  }, [dispatch, orderServiceAddress?.id, selectedServices]);

  useEffect(() => {
    allProviders && providerListToDisplayFn(allProviders);
  }, [allProviders, providerListToDisplayFn]);

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    variableWidth: true,
    slidesToScroll: 3,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          initialSlide: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className='max-w-9xl mx-auto mt-10 w-full'>
      {!isEmpty(allProviders) && (
        <div className='flex items-center'>
          <h4 className=' text-lg font-semibold text-[#68D3A4]'>Best Matches In Your Neighborhood</h4>
        </div>
      )}

      <div className='my-4'>
        {allProvidersLoading ? (
          <Loader classes='h-96 w-full' bg={true} text='Search for providers' />
        ) : (
          <div className='w-full'>
            <Slider {...settings}>
              {providersList?.map(
                (provider, i) =>
                  provider && (
                    <SingleProvider
                      key={i}
                      provider={provider}
                      onSelectProvider={onSelectProvider}
                      orderProvider={orderProvider}
                      allProviders={allProviders}
                    />
                  )
              )}
            </Slider>
          </div>
        )}
      </div>
    </div>
  );
}
