import React, { useEffect, useState } from 'react';
import AccordionBasic from '../../components/AccordionBasic';
import { resetCreateOrderFlow } from '../../redux/Reducers/CreateOrderReducer';
import { useDispatch } from 'react-redux';
import { getSupportApi } from '../../redux/Reducers/commonApis';
import Loader from '../../components/Loader';

const chatsvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='mr-1.5 h-5 w-5'
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
    strokeWidth={2}
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z'
    />
  </svg>
);
const callsvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='mr-1.5 h-5 w-5'
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
    strokeWidth={2}
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z'
    />
  </svg>
);
const emailsvg = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='mr-1.5 h-5 w-5'
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
    strokeWidth={2}
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z'
    />
  </svg>
);
export default function Help() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const getPrivacyFn = (id) => {
    setLoading(true);
    const response = getSupportApi();
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  useEffect(() => {
    getPrivacyFn();
  }, []);
  return (
    <div>
      {loading ? (
        <Loader text='loading ...' classes='h-96' />
      ) : (
        <div>
          <div className='mb-5 rounded-lg bg-white p-5'>
            <h1 className='go-textblackbold-24 py-8 text-center'>Reach out to our support team directly</h1>
            <div className=' justify-center sm:mx-60 sm:flex'>
              <div className='mx-10'>
                <p className='go-label-18 mb-2'>Support days and hours:</p>
                {data?.support_schedules?.map((item, index) => (
                  <div className='mb-2 flex' key={index}>
                    <span className='go-textblacknormal-14 w-24'>{item?.weekdays}</span>
                    <span className='go-textblackmedium-14'>{item?.hours}</span>
                  </div>
                ))}
              </div>
              <div className='mx-10'>
                {/* <div className='bg-client-link mb-4 flex w-52 justify-center rounded-lg p-3 text-center text-white'>
                  <span>{chatsvg}</span>
                  <span className=''> Chat</span>
                </div> */}
                {data?.phone_number && (
                  <div className='bg-client-link mb-4 w-52  rounded-lg p-3 text-center text-white'>
                    <div className='flex justify-center '>
                      <span>{callsvg}</span>
                      <span className=''> Call</span>
                    </div>
                    <span>{data?.phone_number}</span>
                  </div>
                )}

                <div className='bg-client-link mb-4 w-52  rounded-lg p-3 text-center text-white'>
                  <div className='flex justify-center '>
                    <span>{emailsvg}</span>
                    <span className=''> Email</span>
                  </div>
                  {data?.email && <span>{data?.email}</span>}
                </div>
              </div>
            </div>
          </div>
          <h1 className='go-textblacknormal-20 mb-3'>FAQ</h1>
          {data?.categories?.map((item, index) => (
            <div className='mt-2' key={index}>
              <p className=' mb-2 text-lg font-semibold'>{item?.name}</p>
              <div className='flex flex-1 flex-wrap  '>
                {item?.questions?.map((itm, idx) => (
                  <div className='w-full sm:w-1/2' key={idx}>
                    <div className='mb-2 mr-2 rounded-lg bg-white'>
                      <AccordionBasic title={itm?.question} status={idx <= 1 ? true : false}>
                        {itm?.answer}
                      </AccordionBasic>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
