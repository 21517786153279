import React, { useEffect, useState } from 'react';
import servicesAndPricingData from '../../mockdata/servicesandpricing';
import icon from '../../images/clothes.png';
import DropdownSingleAddress from '../../components/DropdownSingleAddress';
import { useSelector } from 'react-redux';
import { getServicesAndPricingApi } from '../../redux/Reducers/commonApis';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import { toast } from 'react-hot-toast';
import { amountCheckFn } from '../../utils/commonUtil';

function ServiceAddressDropdown({ allServiceAddresses, selectedAddress }) {
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    if (allServiceAddresses) {
      const locations = allServiceAddresses.map((address) => ({
        id: address.id,
        value: `${address?.name}, ${address?.address_line_1}`,
      }));
      setAllLocations(locations);
    }
  }, [allServiceAddresses]);

  return <DropdownSingleAddress dropdownValues={allLocations} newItem={false} />;
}

function ServiceItem({ item }) {
  return (
    <div className='col-span-full rounded-lg bg-white xl:col-span-4'>
      <div className='flex h-full flex-col p-5'>
        <div className='grow'>
          <header className='mb-4 flex items-start'>
            <div className='bolder-slate-800 rounded-sm border p-2'>
              <img src={item?.image?.absolute_url || icon} alt='image' className='w-24' />
            </div>
            <h3 className='ml-2 text-sm font-semibold text-black'>{item?.name}</h3>
          </header>
          <div className='text-sm text-slate-700'>{item?.description}</div>
        </div>
        <footer className='mt-4'>
          <div className='my-2 flex flex-wrap items-center justify-between'>
            <div className='text-sm font-semibold'>Price</div>
            <span className='text-sm font-semibold'>{amountCheckFn(item?.client_fee)}</span>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default function ServicesAndPricing() {
  const { allServiceAddresses, selectedAddress } = useSelector((state) => state.serviceAddresses);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const response = getServicesAndPricingApi(selectedAddress?.id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  }, [selectedAddress]);

  return (
    <div>
      <div className='relative flex items-center'>
        <div className='go-heading-18 font-medium'>
          Service Address -
          <ServiceAddressDropdown allServiceAddresses={allServiceAddresses} selectedAddress={selectedAddress} />
        </div>
      </div>
      <div className='mt-4'>
        {loading ? (
          <Loader text='loading services and pricing details...' classes='h-96' />
        ) : data?.length === 0 ? (
          <NoData text='No services are available at this address' classes='h-96' />
        ) : (
          <div className='grid grid-cols-12 gap-6'>
            {data?.map((item, idx) => (
              <ServiceItem key={idx} item={item} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}