import React from 'react';
import { Rating } from 'react-simple-star-rating';

export default function RatingStars({ ratingValue, onClick, readonly = false, size = 22 }) {
  return (
    <div
      style={{
        direction: 'ltr',
        fontFamily: 'sans-serif',
        touchAction: 'none',
      }}
    >
      <Rating
        readonly={readonly}
        fillColor='#548FF7'
        emptyColor='#BCD3FC'
        size={size}
        onClick={onClick}
        initialValue={ratingValue}
        SVGclassName='inline-block'
      />
    </div>
  );
}
