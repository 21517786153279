import React from 'react';
import statusColor from '../../../utils/statusColors';
import StatusChip from '../../../components/StatusChip';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { Link } from 'react-router-dom';

export default function singleOrder({ order }) {
  return (
    <div className='py-2'>
      <div className='w-full'>
        <div className='mb-0.5 items-start justify-between sm:flex'>
          <Link to={`/order/${order?.id}`} className='go-label-14 mb-2 '>
            Order {order?.id}
          </Link>
        </div>
        <div className='my-1.5 flex items-start justify-between'>
          <div className='mb-3 items-center'>
            <Link to={`/order/${order?.id}`} className='go-textblacknormal-18 mr-1 '>
              {order?.items[0]?.name}
            </Link>
            {order?.items.length > 1 && (
              <span className='text-xs'>{`(+${order?.items?.length - 1} more service)`}</span>
            )}
          </div>
          <StatusChip status={order?.status} />
        </div>
        <div className='flex'>
          <div className='mb-2  whitespace-nowrap text-xs font-medium'>
            <span className='go-label-15'>Pick up:</span>
            <span className='go-textblackmedium-15 pl-2'>
              {dateFn('toLocal', order?.pickup_date, order?.pickup_start_time, order?.pickup_end_time)}
            </span>
          </div>
        </div>
        <div className='flex'>
          <div className=' mb-2 whitespace-nowrap text-xs font-medium'>
            <span className='go-label-15'>Delivery:</span>
            <span className='go-textblackmedium-15 pl-2'>
              {dateFn('toLocal', order?.dropoff_date, order?.dropoff_start_time, order?.dropoff_end_time)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
