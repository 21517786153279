import React, { useState } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import StatusChip from '../../../components/StatusChip';
import { Link } from 'react-router-dom';
import PhoneNumbersList from '../../../components/PhoneNumbersList';
import { titleCaseFormatter } from '../../../utils/stringFormatters';

export default function MyUser({ edit, setEdit, data }) {
  return (
    <div className=''>
      <div className='z-0 rounded-lg bg-white '>
        <header className='flex items-center border-b border-gray-100 p-6'>
          <h5 className='go-textnormal-18 my-1 flex-1'>My User</h5>
          <div className='flex'>
            <button className='go-btn-primary mr-2'>
              <EditIcon
                onClick={() => {
                  setEdit(!edit);
                }}
              />
            </button>
          </div>
        </header>
        <div className='p-6'>
          <div className='mb-4 flex-row space-y-1.5'>
            <div className='go-label-16'>Name</div>
            {data?.account_type === 'ORGANIZATION' ? (
              <div className='go-textblacknormal-16 font-medium'>
                {data?.org_admin_first_name + ' ' + data?.org_admin_last_name}
              </div>
            ) : (
              <div className='go-textblacknormal-16 font-medium'>{data?.first_name + ' ' + data?.last_name}</div>
            )}
          </div>
          <div className='mb-4 flex-row space-y-1.5'>
            <div className='go-label-16'>Email</div>
            <div className='go-textblacknormal-16 font-medium'>{data?.email}</div>
          </div>
          <div className='mb-4 flex-row space-y-1.5'>
            {data?.account_type === 'ORGANIZATION' ? (
              <PhoneNumbersList phoneNumbers={data?.org_admin_phone_number && data?.org_admin_phone_number} />
            ) : (
              <PhoneNumbersList phoneNumbers={data?.phone_numbers && data?.phone_numbers} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
