import React, { useEffect, useState } from 'react';
import ClientButton from '../../../components/ClientButton';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
import { getBillingDetailsApi } from '../../../redux/Reducers/commonApis';
import { titleCaseFormatter } from '../../../utils/stringFormatters';
import Stripe from '../../../components/Stripe';
import Loader from '../../../components/Loader';
import { textCheckFn } from '../../../utils/commonUtil';
export default function PaymentMethodAndPreferences({ services, addService, availableServices }) {
  const dispatch = useDispatch();
  const { orderPromoCode, orderSummaryError, orderServiceAddress } = useSelector((state) => state.createNewOrder);
  const [paymentAvailableType, setPaymentAvailableType] = useState(false);
  const [paymentAvailable, setPaymentAvailable] = useState(false);
  const [addNewCard, setAddNewCard] = useState(false);
  const [promoCode, setPromoCode] = useState(orderPromoCode || '');
  const [billingDetails, setBillingDetails] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    promoCode === '' && applyPromoCode();
  }, [promoCode]);

  const applyPromoCode = () => {
    dispatch(setOrderDataAction({ orderPromoCode: promoCode }));
  };
  const getBillingDetailsFn = (id) => {
    setLoading(true);
    const response = getBillingDetailsApi(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        const details = res?.data?.addresses?.find((item) => item?.id === id);
        dispatch(
          setOrderDataAction({
            orderPaymentDetails: details || null,
          })
        );
        setBillingDetails(details || {});
        setPaymentAvailableType(details?.billing_details?.default_payment_method_type || null);
        setPaymentAvailable(details?.billing_details?.default_payment_method || null);
        setLoading(false);
      } else {
        setPaymentAvailableType(null);
        setPaymentAvailable(null);
        toast.error(res?.message || 'Something went wrong');
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getBillingDetailsFn(orderServiceAddress?.id);
  }, [orderServiceAddress?.id]);

  return (
    <div className='z-0 col-span-full flex flex-col rounded-lg bg-white p-6 shadow-lg xl:col-span-7'>
      <header className='flex items-center pb-3'>
        <h5 className='go-heading-18'>Confirm Payment Method</h5>
      </header>
      <hr className='go-hr-dashed '></hr>
      {loading ? (
        <Loader text='loading payment details' classes='h-32' />
      ) : (
        <div className='grid gap-0 sm:gap-4 xl:grid-cols-2'>
          {paymentAvailableType == 'STRIPE' && paymentAvailable && !addNewCard ? (
            <div className='py-2 sm:py-4'>
              <label htmlFor='paymentlabel' className='go-label-16'>
                Payment Method
              </label>
              <input
                id='paymentlabel'
                className='form-input bg-client-inputdisabledbg mt-2 w-full border-0 font-medium '
                type='text'
                defaultValue={`${titleCaseFormatter(
                  billingDetails?.billing_details?.default_payment_method?.card_brand
                )} **** **** **** ${billingDetails?.billing_details?.default_payment_method?.card_last4}`}
                disabled
              />
            </div>
          ) : paymentAvailableType == 'ACH' || paymentAvailableType == 'CHECK' ? (
            <div className='py-2 sm:py-4'>
              <label htmlFor='paymentlabel' className='go-label-16'>
                Payment Method
              </label>
              <p htmlFor='paymentlabel' className='go-heading-18'>
                {textCheckFn(paymentAvailableType)}
              </p>
            </div>
          ) : (
            <div className='py-2 sm:py-4'>
              {!addNewCard && (
                <p htmlFor='paymentlabel' className='go-heading-18'>
                  You don't have a payment method.
                </p>
              )}

              {addNewCard && (
                <div className=''>
                  <Stripe
                    addressid={orderServiceAddress?.id}
                    cardAdded={() => {
                      getBillingDetailsFn(orderServiceAddress?.id);
                      setAddNewCard(false);
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <div className=' flex justify-center py-2 sm:items-end sm:justify-end sm:py-4'>
            {!addNewCard && paymentAvailableType != 'ACH' && paymentAvailableType != 'CHECK' && (
              <ClientButton
                variant='primary'
                text={
                  paymentAvailableType == 'STRIPE'
                    ? !paymentAvailable
                      ? 'Add a credit card'
                      : 'Update Credit Card'
                    : 'Add a credit card'
                }
                classes='mr-2'
                onClick={() => setAddNewCard(true)}
              />
            )}
          </div>
        </div>
      )}

      <hr className='go-hr-dashed '></hr>
      <div className=' flex'>
        <div className='mt-5 mb-2 w-full'>
          <label className='go-label-16 mb-1 block' htmlFor='label'>
            Promo Code
          </label>
          <input
            id='label'
            className='go-inputtext-16 '
            type='text'
            defaultValue={promoCode}
            onChange={(e) => setPromoCode(e?.target?.value)}
          />
          {orderSummaryError && <span className='text-red-500'>{orderSummaryError}</span>}
        </div>
      </div>
      <div className=' flex'>
        <ClientButton size='small' variant='primary' text='Apply' onClick={() => applyPromoCode()} classes='' />
      </div>
    </div>
  );
}
