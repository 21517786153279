import React, { useEffect, useState } from 'react';
import ModalWithHeadingSmall from '../../../components/ModalWithHeadingSmall';
import moment from 'moment';
import ClientButton from '../../../components/ClientButton';
import { updateServiceAddressesApi } from '../../../redux/Reducers/ServiceAddressesReducer';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { dateFn } from '../../../utils/dateTimeFormatter';

export default function ModalForOrders({ modal, updateModal, existingOrders, newAddress, oldAddress }) {
  const dispatch = useDispatch();
  const [tempAddress, setTempAddress] = useState(null);
  const [selectedOrderNo, setSelectedOrderNo] = useState({
    cancel_orders: [],
    warning_confirmation: false,
  });
  const onChangeOrderNo = (e, orderNo, warningMsg) => {
    if (e.target.checked) {
      setSelectedOrderNo({
        ...selectedOrderNo,
        cancel_orders: [...selectedOrderNo?.cancel_orders, orderNo],
        warning_confirmation: true,
      });
    } else {
      setSelectedOrderNo({
        ...selectedOrderNo,
        cancel_orders: selectedOrderNo?.cancel_orders?.filter((item) => item != orderNo),
      });
    }
  };
  const updateNewAddress = (e) => {
    const create = dispatch(updateServiceAddressesApi(tempAddress, oldAddress?.id));
    create.then((res) => {
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        updateModal(e, true, oldAddress?.id, 'update');
      } else {
        toast.error(res?.error?.message || 'Something went wrong');
      }
    });
  };
  useEffect(() => {
    setTempAddress({ ...tempAddress, ...selectedOrderNo });
  }, [selectedOrderNo]);
  useEffect(() => {
    setTempAddress(newAddress);
  }, [newAddress]);

  return (
    <div>
      <ModalWithHeadingSmall
        modalOpen={modal}
        setModalOpen={updateModal}
        title={existingOrders?.message}
        classes='max-w-2xl py-10'
      >
        <div className='mx-8 py-5'>
          <div className='flex-row space-y-5'>
            <div>
              <div className='max-w-9xl mx-auto w-full'>
                <div className='mb-4 max-h-[400px] min-h-[200px] flex-row overflow-y-scroll border border-gray-200 px-10 py-2'>
                  {existingOrders?.orders?.map((order, index) => (
                    <div className='m-3' key={index}>
                      <div className='flex items-start'>
                        <input
                          type='checkbox'
                          className='form-checkbox my-1'
                          onChange={(e) => onChangeOrderNo(e, order?.id, order?.warning_message)}
                          defaultChecked={selectedOrderNo?.cancel_orders?.includes(order?.id)}
                        />
                        <div>
                          <span className='go-label-16 ml-4 block'>Order #{order?.id}</span>
                          <span className='go-textblackmedium-16 my-1 ml-4 block'>
                            Pickup:{' '}
                            {dateFn('toLocal', order?.pickup_date, order?.pickup_start_time, order?.pickup_end_time)}
                          </span>
                          <span className='go-textblackmedium-16 my-1 ml-4 block'>
                            Delivery:{' '}
                            {dateFn('toLocal', order?.dropoff_date, order?.dropoff_start_time, order?.dropoff_end_time)}
                          </span>
                          {order?.warning_message && (
                            <p className='ml-4 border border-red-100 bg-red-50 p-1 text-red-600'>
                              {order?.warning_message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='flex flex-row justify-center px-24'>
                <ClientButton variant='secondary' text='Close' size='small' onClick={(e) => updateModal(e)} />
                <ClientButton
                  variant='primary'
                  text='Next'
                  size='small'
                  onClick={(e) => updateNewAddress(e)}
                  disabled={selectedOrderNo?.cancel_orders?.length == 0}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalWithHeadingSmall>
    </div>
  );
}
