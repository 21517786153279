import React, { useState } from 'react';

function AccordionProviderTimes(props) {
  const [open, setOpen] = useState(props.status);

  return (
    <div className=' mb-1 rounded bg-[#f5f5fb] px-3 py-1'>
      <button
        className={`group flex w-full items-center justify-between`}
        aria-expanded={open}
        onClick={() => setOpen(!open)}
      >
        <div className='text-left text-sm font-semibold text-indigo-900 '>{props.title}</div>
        <svg
          className={`text-portal-600 group-hover:text-portal-600 ml-3 h-8 w-8 shrink-0 fill-current ${
            open && 'rotate-180'
          }`}
          viewBox='0 0 32 32'
        >
          <path d='M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z' />
        </svg>
      </button>
      <div className={`text-sm font-medium text-indigo-900 ${!open && 'hidden'}`}>{props.children}</div>
    </div>
  );
}

export default AccordionProviderTimes;
