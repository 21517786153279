import React, { useEffect, useState } from 'react';
import DropdownDateTime from '../../../components/DropdownDateTime';
import { useDispatch, useSelector } from 'react-redux';
import { ClockIcon, CalendarDaysIcon } from '@heroicons/react/24/outline';
import { getDeliveryTimeApi, getPickupTimeApi } from '../../../redux/Reducers/CreateOrderReducer';
import { dateFn, utcTimeToLocalTime } from '../../../utils/dateTimeFormatter';

import { drop, isEmpty, pick, set } from 'lodash';
import { toast } from 'react-hot-toast';
import Loader from '../../../components/Loader';
import moment from 'moment';
import { amountCheckFn, formatAndSortStartTimes, timeIdGenarate } from '../../../utils/commonUtil';
import { setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
export default function PickupDeliveryEdit({ orderDetails, newOrderDetails, setNewOrderDetails }) {
  const dispatch = useDispatch();
  const [selectedTimeSlots, setSelectedTimeSlots] = useState(null);
  const [availableSchedules, setAvailableSchedules] = useState(null);
  const [availableDeliverySchedules, setAvailableDeliverySchedules] = useState(null);
  const [pickupDatesForOneTime, setPickupDatesForOneTime] = useState([]);
  const [deliveryDatesForOneTime, setDeliveryDatesForOneTime] = useState([]);
  const [pickupTimes, setPickupTimes] = useState([]);
  const [deliveryTimes, setDeliveryTimes] = useState([]);
  const [datesLoading, setDatesLoading] = useState(false);
  const genaratePickupDates = (times) => {
    const pickupDatesOneTime = Object.keys(times).map((date, idx) => {
      return {
        id: dateFn('YYYYMMDD', date),
        value: dateFn('type3', date),
        dateYYYYMMDD: dateFn('YYYY-MM-DD', date),
      };
    });
    setPickupDatesForOneTime(pickupDatesOneTime || []);
  };
  const genarateDeliveryDates = (times) => {
    const deliveryDatesOneTime = Object.keys(times)?.map((date, idx) => {
      return {
        id: dateFn('YYYYMMDD', date),
        value: dateFn('type3', date),
        dateYYYYMMDD: dateFn('YYYY-MM-DD', date),
      };
    });
    setDeliveryDatesForOneTime(deliveryDatesOneTime || []);
  };

  const onPickupDateChange = (date) => {
    if (date) {
      const day = dateFn('day', date)?.toLowerCase();
      const times = availableSchedules?.[date]?.map((item) => {
        return item?.weekday?.toLowerCase() === day && item;
      });
      const YYMMDDDate = moment(date).format('YYYY-MM-DD');
      const pickupTimes = times?.map((time, idx) => {
        return {
          value: `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
            `${YYMMDDDate}T${time?.end_time}`
          )}`,
          default: {
            start_time: time?.start_time,
            end_time: time?.end_time,
          },
          id: timeIdGenarate(YYMMDDDate, time?.start_time),
        };
      });
      setPickupTimes(pickupTimes || []);
    }
  };

  const onDeliveryDateChange = (date) => {
    if (date) {
      const day = dateFn('day', date)?.toLowerCase();
      const times = availableDeliverySchedules?.[date]?.map((item) => {
        return item?.weekday?.toLowerCase() === day && item;
      });
      const YYMMDDDate = moment(date).format('YYYY-MM-DD');
      const deliveryTimes = times?.map((time, idx) => {
        return {
          value: `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
            `${YYMMDDDate}T${time?.end_time}`
          )}`,
          default: {
            start_time: time?.start_time,
            end_time: time?.end_time,
          },
          priority_fee: !isNaN(time?.priority_fee) && time?.priority_fee >= 0 && time?.priority_fee,
          is_rush: time?.is_rush && time?.is_rush,
          displayValue: genarateDisplayValueFn(YYMMDDDate, time),
          id: timeIdGenarate(YYMMDDDate, time?.start_time),
        };
      });
      setDeliveryTimes(deliveryTimes || []);
    }
  };

  const genarateDisplayValueFn = (YYMMDDDate, time) => {
    if (!time?.is_rush) {
      return `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
        `${YYMMDDDate}T${time?.end_time}`
      )}`;
    } else if (!isNaN(time?.priority_fee)) {
      if (time?.priority_fee === 0) {
        return `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
          `${YYMMDDDate}T${time?.end_time}`
        )}`;
      } else if (time?.priority_fee > 0) {
        return `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
          `${YYMMDDDate}T${time?.end_time}`
        )} for +${amountCheckFn(time?.priority_fee)}`;
      }
    } else {
      return `${utcTimeToLocalTime(`${YYMMDDDate}T${time?.start_time}`)} - ${utcTimeToLocalTime(
        `${YYMMDDDate}T${time?.end_time}`
      )}`;
    }
  };
  const getPickupTimeFn = (provider, address) => {
    const response = getPickupTimeApi(provider, address);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        const data = formatAndSortStartTimes(res?.data || {});
        setAvailableSchedules(data || []);
        const selectedPickupDate = orderDetails?.pickup_date;
        const selectedPickupTime = orderDetails?.pickup_start_time;
        const selectedPickupEndTime = orderDetails?.pickup_end_time;
        const selectedPickupTimeObj = res?.data?.[selectedPickupDate]?.find(
          (item) => item?.start_time === selectedPickupTime && item?.end_time === selectedPickupEndTime
        );
        const selectedPickupTimeId = timeIdGenarate(selectedPickupDate, selectedPickupTimeObj?.start_time);
        setSelectedTimeSlots((prevState) => ({
          ...prevState,
          selected_pickup_time_id: selectedPickupTimeId,
        }));
      } else {
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };

  const getDeliveryTimeFn = async (date, type) => {
    setDatesLoading(true);
    setDeliveryDatesForOneTime([]);
    const data = {
      items: orderDetails?.items?.map((item) => {
        return {
          service_id: item?.service?.id,
          quantity: item?.quantity,
        };
      }),
      pickup_date: selectedTimeSlots?.pickup_date,
      pickup_start_time: selectedTimeSlots?.pickup_start_time,
      type: type,
    };
    try {
      const response = await getDeliveryTimeApi(
        orderDetails?.worker?.id || null,
        orderDetails?.customer_address?.id,
        data
      );
      if (response?.status === 200 || response?.status === 201) {
        setDatesLoading(false);
        const data = formatAndSortStartTimes(response?.data?.data || {});
        if (
          selectedTimeSlots?.pickup_date === orderDetails?.pickup_date &&
          selectedTimeSlots?.pickup_start_time === orderDetails?.pickup_start_time &&
          selectedTimeSlots?.pickup_end_time === orderDetails?.pickup_end_time
        ) {
          const selectedDropoffTimeObj = data?.[orderDetails?.dropoff_date]?.find(
            (item) =>
              item?.start_time === orderDetails?.dropoff_start_time && item?.end_time === orderDetails?.dropoff_end_time
          );
          const selectedDropoffTimeId = timeIdGenarate(orderDetails?.dropoff_date, selectedDropoffTimeObj?.start_time);
          setSelectedTimeSlots((prevState) => ({
            ...prevState,
            selected_dropoff_time_id: selectedDropoffTimeId,
            dropoff_date: orderDetails?.dropoff_date,
          }));
        } else {
          const firstKey = Object.keys(data)[0];
          setSelectedTimeSlots((prevState) => ({
            ...prevState,
            // selected_dropoff_time_id: data?.[firstKey]?.[0]?.id,
            selected_dropoff_time_id: timeIdGenarate(firstKey, data?.[firstKey]?.[0]?.start_time),
            dropoff_start_time: data?.[firstKey]?.[0]?.start_time,
            dropoff_end_time: data?.[firstKey]?.[0]?.end_time,
            dropoff_date: firstKey,
          }));
          onPriorityfeeChange(data?.[firstKey]?.[0]);
        }
        setAvailableDeliverySchedules(data);
        if (isEmpty(data)) {
          toast.error('No delivery times available for the selected pickup date and time');
          setDeliveryDatesForOneTime([]);
          setDeliveryTimes([]);
          setSelectedTimeSlots((prevState) => ({
            ...prevState,
            dropoff_date: null,
          }));
        }
      } else {
        setDatesLoading(false);
        toast.error(response?.message || 'Something went wrong');
      }
    } catch (error) {
      setDatesLoading(false);
      toast.error(error?.response?.data?.message || 'Something went wrong');
    }
  };
  useEffect(() => {
    getPickupTimeFn(orderDetails?.worker?.id || null, orderDetails?.customer_address?.id);
  }, [orderDetails?.worker?.id, orderDetails?.customer_address?.id]);

  useEffect(() => {
    if (!isEmpty(availableSchedules)) {
      genaratePickupDates(availableSchedules);
    }
  }, [availableSchedules]);

  useEffect(() => {
    if (selectedTimeSlots?.pickup_date) {
      getDeliveryTimeFn(selectedTimeSlots?.pickup_date, 'ORDER');
    }
  }, [selectedTimeSlots?.selected_pickup_time_id]);

  useEffect(() => {
    if (!isEmpty(availableDeliverySchedules)) {
      genarateDeliveryDates(availableDeliverySchedules);
    }
  }, [availableDeliverySchedules]);

  useEffect(() => {
    availableSchedules && selectedTimeSlots?.pickup_date && onPickupDateChange(selectedTimeSlots?.pickup_date);
  }, [availableSchedules, selectedTimeSlots?.pickup_date]);

  useEffect(() => {
    availableDeliverySchedules &&
      selectedTimeSlots?.dropoff_date &&
      onDeliveryDateChange(selectedTimeSlots?.dropoff_date);
  }, [availableDeliverySchedules, selectedTimeSlots?.dropoff_date]);

  useEffect(() => {
    setSelectedTimeSlots((prevState) => ({
      ...prevState,
      pickup_date: orderDetails?.pickup_date || {},
      pickup_start_time: orderDetails?.pickup_start_time || {},
      pickup_end_time: orderDetails?.pickup_end_time || {},
      dropoff_date: orderDetails?.dropoff_date || {},
      dropoff_start_time: orderDetails?.dropoff_start_time || {},
      dropoff_end_time: orderDetails?.dropoff_end_time || {},
    }));
  }, [orderDetails]);

  useEffect(() => {
    setNewOrderDetails((prevState) => ({
      ...prevState,
      pickup_date: selectedTimeSlots?.pickup_date,
      pickup_start_time: selectedTimeSlots?.pickup_start_time,
      pickup_end_time: selectedTimeSlots?.pickup_end_time,
      dropoff_date: selectedTimeSlots?.dropoff_date,
      dropoff_start_time: selectedTimeSlots?.dropoff_start_time,
      dropoff_end_time: selectedTimeSlots?.dropoff_end_time,
    }));
  }, [selectedTimeSlots]);

  const onPriorityfeeChange = (time) => {
    dispatch(setOrderDataAction({ editOrderIsRush: time }));
  };
  return (
    <>
      {datesLoading ? (
        <Loader text='fetching dates...' classes='h-52' />
      ) : (
        !isEmpty(selectedTimeSlots) && (
          <div className='my-5'>
            <div className='grid gap-0 sm:gap-4 xl:grid-cols-2'>
              {!isEmpty(pickupDatesForOneTime) && (
                <div className='py-2 sm:py-4'>
                  <label className='go-label-16'>Pickup Date</label>
                  <DropdownDateTime
                    dropdownValues={pickupDatesForOneTime || []}
                    icon={<CalendarDaysIcon />}
                    onChange={(date) => {
                      setSelectedTimeSlots((prevState) => ({
                        ...prevState,
                        pickup_date: date?.dateYYYYMMDD,
                        pickup_start_time: availableSchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time,
                        pickup_end_time: availableSchedules?.[date?.dateYYYYMMDD]?.[0]?.end_time,
                        // selected_pickup_time_id: availableSchedules?.[date?.dateYYYYMMDD]?.[0]?.id,
                        selected_pickup_time_id: timeIdGenarate(
                          date?.dateYYYYMMDD,
                          availableSchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time
                        ),
                      }));
                    }}
                    defaultValue={pickupDatesForOneTime?.find(
                      (item) => item?.id === dateFn('YYYYMMDD', selectedTimeSlots?.pickup_date)
                    )}
                    type='date'
                  />
                </div>
              )}

              {!isEmpty(pickupTimes) && (
                <div className='py-2 sm:py-4'>
                  <label className='go-label-16'>Pickup Time</label>
                  <DropdownDateTime
                    dropdownValues={pickupTimes || []}
                    icon={<ClockIcon />}
                    onChange={(time) => {
                      setSelectedTimeSlots((prevState) => ({
                        ...prevState,
                        pickup_start_time: time?.default?.start_time,
                        pickup_end_time: time?.default?.end_time,
                        selected_pickup_time_id: time?.id,
                      }));
                    }}
                    defaultValue={pickupTimes?.find((item) => item?.id === selectedTimeSlots?.selected_pickup_time_id)}
                    placeholder='Select Time'
                    type='time'
                  />
                </div>
              )}
            </div>
            <div className='grid gap-0 sm:gap-4 xl:grid-cols-2'>
              {!isEmpty(deliveryDatesForOneTime) && (
                <div className='py-2 sm:py-4'>
                  <label className='go-label-16'>Delivery Date</label>
                  <DropdownDateTime
                    dropdownValues={deliveryDatesForOneTime || []}
                    icon={<CalendarDaysIcon />}
                    onChange={(date) => {
                      setSelectedTimeSlots((prevState) => ({
                        ...prevState,
                        dropoff_date: date?.dateYYYYMMDD,
                        dropoff_start_time: availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time,
                        dropoff_end_time: availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.end_time,
                        // selected_dropoff_time_id: availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.id,
                        selected_dropoff_time_id: timeIdGenarate(
                          date?.dateYYYYMMDD,
                          availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]?.start_time
                        ),
                      }));
                      onPriorityfeeChange(availableDeliverySchedules?.[date?.dateYYYYMMDD]?.[0]);
                    }}
                    defaultValue={deliveryDatesForOneTime?.find(
                      (item) => item?.id === dateFn('YYYYMMDD', selectedTimeSlots?.dropoff_date)
                    )}
                    type='date'
                  />
                </div>
              )}
              {!isEmpty(deliveryTimes) && (
                <div className='py-2 sm:py-4'>
                  <label className='go-label-16'>Delivery Time</label>
                  <DropdownDateTime
                    dropdownValues={deliveryTimes || []}
                    icon={<ClockIcon />}
                    onChange={(time) => {
                      setSelectedTimeSlots((prevState) => ({
                        ...prevState,
                        selected_dropoff_time_id: time?.id,
                        dropoff_start_time: time?.default?.start_time,
                        dropoff_end_time: time?.default?.end_time,
                      }));
                      onPriorityfeeChange(time);
                    }}
                    checkIf={true}
                    defaultValue={deliveryTimes?.find(
                      (item) => item?.id === selectedTimeSlots?.selected_dropoff_time_id
                    )}
                    type='time'
                  />
                </div>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
}
