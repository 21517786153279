import React from 'react';
import StatusChip from '../../../components/StatusChip';
import ClientButton from '../../../components/ClientButton';
import RatingStars from '../../../components/RatingStars';
import no_picture from '../../../images/no-profile-picture.png';
import { useNavigate } from 'react-router-dom';
import { dateFn } from '../../../utils/dateTimeFormatter';
import { amountCheckFn } from '../../../utils/commonUtil';

function SingleOrder({ order, tipModalOpen, ratingModalOpen }) {
  const navigate = useNavigate();
  return (
    <div className={`my-4 rounded-xl bg-white p-6`}>
      <div className='items-center justify-between space-y-2 space-x-2 sm:space-y-4 md:flex md:space-y-0'>
        <div
          className='flex w-full cursor-pointer  space-x-2 sm:w-8/12 sm:space-x-4'
          onClick={() => navigate(`/order/${order?.id}`)}
        >
          <div className='sm:2/12 flex  w-3/12 items-center justify-center'>
            <div className='mb-2 text-center'>
              <div className='inline-flex'>
                <img
                  className='m-0 h-[60px] w-[60px] rounded-full sm:h-[100px] sm:w-[100px]'
                  src={order?.worker ? order?.worker?.profile_image?.absolute_url || no_picture : no_picture}
                  alt={order?.worker ? order?.worker?.first_name : 'no picture'}
                />
              </div>
              <div className='go-textblacknormal-16 '>
                {order?.worker && `${order?.worker?.first_name} ${order?.worker?.last_name}`}
              </div>
            </div>
          </div>
          <div className='w-full space-y-0.5 sm:space-y-1.5'>
            <div>
              <span className='go-label-16'>Order #{order?.id}</span>
              <span className='ml-5'>
                <StatusChip status={order?.status} />
              </span>
            </div>
            <div className=' go-textblacknormal-20'>
              {(order?.items && order?.items[0]?.service?.name) || 'Services'}
            </div>
            <div>
              <span className='go-label-14 pr-2'>Pick up:</span>

              <span className='go-textblacknormal-14 '>
                {dateFn('toLocal', order?.pickup_date, order?.pickup_start_time, order?.pickup_end_time)}
              </span>
            </div>
            <div>
              <span className='go-label-14 pr-2'>Delivery:</span>
              <span className='go-textblacknormal-14 '>
                {dateFn('toLocal', order?.dropoff_date, order?.dropoff_start_time, order?.dropoff_end_time)}
              </span>
            </div>
            <div>
              <span className='go-label-14 pr-2'>Estimated Total:</span>
              <span className='go-textblacknormal-14'>{amountCheckFn(order?.estimated_total)}</span>
            </div>
          </div>
        </div>
        <div className='w-full  flex-row items-end space-y-1 sm:w-4/12 sm:space-y-3'>
          {(order?.status == 'WAITING' || order?.status == 'PICKUP_COMPLETE' || order?.status == 'DELIVERED') &&
            (order?.final_tip ? (
              <div className='text-center'>
                <span className='go-text-16'>Tip: {amountCheckFn(order?.final_tip)}</span>
              </div>
            ) : dateFn('daysBetween', order?.dropoff_date) <= 30 ? (
              <div className='text-center'>
                <ClientButton
                  variant='secondary'
                  text='Leave Тip'
                  onClick={(e) => {
                    tipModalOpen(e, true, order);
                  }}
                />
              </div>
            ) : (
              <div className='text-center'>
                <span className='go-text-16'>Tip: $0 </span>
              </div>
            ))}
          {order?.status == 'DELIVERED' &&
            (order?.tenant_rating ? (
              <>
                <div className='flex items-center pr-2'>
                  <span className='go-textnormal-14 w-1/2'>Your Company Rating</span>
                  <RatingStars ratingValue={order?.tenant_rating?.rating} readonly={true} size={27} />
                </div>
                <div className='flex items-center pr-2'>
                  <span className='go-textnormal-14 w-1/2'>Your Provider Rating</span>
                  <RatingStars ratingValue={order?.worker_rating?.rating} readonly={true} size={27} />
                </div>
              </>
            ) : (
              <div className='text-center'>
                <ClientButton
                  variant='secondary'
                  text='Rate'
                  onClick={(e) => {
                    ratingModalOpen(e, true, order);
                  }}
                />
              </div>
            ))}

          {(order?.status == 'WAITING' || order?.status == 'PICKUP_COMPLETE' || order?.status == 'CANCELLED') && (
            <div className='text-center'>
              <ClientButton variant='secondary' text='View Order' onClick={() => navigate(`/order/${order?.id}`)} />
            </div>
          )}

          {order?.status == 'DELIVERED' && (
            <div className='text-center'>
              <ClientButton variant='secondary' text='View Receipt' onClick={() => navigate(`/order/${order?.id}`)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SingleOrder;
