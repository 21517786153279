import React, { useEffect, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import AddIcon from '../../../components/Icons/AddIcon';
import Loader from '../../../components/Loader';
import NoData from '../../../components/NoData';
import DeleteMinus from '../../../components/Icons/DeleteMinus';
import { isEmpty } from 'lodash';
import { setOneTimeOrderAction, setOrderDataAction } from '../../../redux/Actions/CreateOrderActions';
import { useDispatch, useSelector } from 'react-redux';
import { numberConverter } from '../../../utils/commonUtil';
function Services({ availableServices, servicesLoading, selectedServices }) {
  const dispatch = useDispatch();
  const { orderPreferences } = useSelector((state) => state.createNewOrder);
  const { preferences } = useSelector((state) => state.myaccount.orderPreferences);
  const [selectedServicesState, setSelectedServicesState] = useState(selectedServices);
  const [currentPreferences, setCurrentPreferences] = useState(orderPreferences || preferences || '');

  const addService = () => {
    const availableServiceIds = availableServices?.map((service) => service?.id);
    const selectedServiceIds = selectedServicesState?.map((service) => service?.service_id);
    const diff = availableServiceIds?.filter((x) => !selectedServiceIds?.includes(x));
    const newService = {
      id: uuidv1(),
      service_id: diff?.length > 0 ? diff[0] : availableServices && availableServices[0]?.id,
      quantity: 1,
    };
    setSelectedServicesState([...selectedServicesState, newService]);
  };

  const onChangeServiceFn = (e, serviceListId) => {
    const tempId = e?.target?.value;
    const serviceId = availableServices?.find((service) => service?.id == tempId)?.id;
    const newServices = selectedServicesState?.map((service) => {
      if (service?.id === serviceListId) {
        return { ...service, service_id: serviceId };
      }
      return service;
    });
    setSelectedServicesState(newServices);
  };

  const onQuanityChangeFn = (e, serviceListId) => {
    const quantity = numberConverter(e?.target?.value);
    const newServices = selectedServicesState?.map((service) => {
      if (service?.id === serviceListId) {
        return { ...service, quantity };
      }
      return service;
    });
    setSelectedServicesState(newServices);
  };

  const onDeleteServiceFn = (serviceListId) => {
    const newServices = selectedServicesState?.filter((service) => service?.id !== serviceListId);
    setSelectedServicesState(newServices);
  };

  useEffect(() => {
    if (!isEmpty(availableServices)) {
      if (!isEmpty(selectedServices)) {
        setSelectedServicesState(selectedServices);
      } else {
        const newService = {
          id: uuidv1(),
          service_id: availableServices && availableServices[0]?.id,
          quantity: 1,
        };
        setSelectedServicesState([newService]);
      }
    }
  }, [availableServices]);

  useEffect(() => {
    dispatch(setOrderDataAction({ selectedServices: selectedServicesState, orderIsRush: { value: false } }));
  }, [selectedServicesState]);

  useEffect(() => {
    dispatch(setOrderDataAction({ orderPreferences: currentPreferences }));
  }, [currentPreferences]);

  return (
    <div className='col-span-full flex flex-col rounded-lg bg-white p-6 shadow-lg xl:col-span-7'>
      <header className='flex items-center pb-3'>
        <h5 className='go-heading-18'>Services</h5>
      </header>
      <hr className='go-hr-dashed '></hr>
      {servicesLoading ? (
        <Loader text='loading services...' classes='h-32' />
      ) : availableServices?.length === 0 ? (
        <NoData text='No services available' classes='h-96' />
      ) : (
        <section className='my-2'>
          {selectedServicesState?.map((service, i) => (
            <div className='flex py-4' key={i}>
              <div className='w-8/12 pr-2'>
                <label className='go-label-13 mb-1 block' htmlFor={`service-${i}`}>
                  {`Service ${i + 1}`}
                </label>
                <select
                  id={`service-${i}`}
                  className='form-select go-textblacknormal-16 w-full'
                  onChange={(e) => onChangeServiceFn(e, service?.id)}
                  value={service?.service_id}
                >
                  {availableServices?.map((item) => (
                    <option
                      key={item?.id}
                      value={item?.id}
                      disabled={selectedServicesState?.find((s) => s?.service_id === item?.id && s?.id !== service?.id)}
                    >
                      {item?.service}
                    </option>
                  ))}
                </select>
              </div>
              <div className='w-4/12 pl-2'>
                <label className='go-label-13 mb-1  flex' htmlFor={`quantity-${i}`}>
                  <span className='hidden sm:block'>Quantity (estimate in </span>
                  <span className='sm:hidden'>Quantity (</span>
                  <span className=' font-semibold text-black'>
                    {availableServices?.find((s) => s?.id === service?.service_id)?.unit}
                  </span>
                  )
                </label>
                <input
                  id={`quantity-${i}`}
                  className='form-input go-inputtext-16 w-full'
                  type='number'
                  value={service?.quantity}
                  tabIndex={i + 1}
                  onChange={(e) => onQuanityChangeFn(e, service?.id)}
                />
              </div>
              <div className='flex w-10 items-end justify-center p-2'>
                {i !== 0 && <DeleteMinus onClick={() => onDeleteServiceFn(service?.id)} />}
              </div>
            </div>
          ))}
        </section>
      )}
      {selectedServicesState?.length < availableServices?.length && (
        <>
          <hr className='go-hr-dashed '></hr>
          <div className='my-5 flex cursor-pointer' onClick={addService}>
            <div className='flex items-center'>
              <AddIcon />
              <a className='go-blue-16 ml-2' href='#'>
                Add one more service
              </a>
            </div>
          </div>
        </>
      )}

      <hr className='go-hr-dashed mt-3 hidden sm:block'></hr>
      <div className='grid gap-0 sm:gap-4'>
        <div className='py-2 sm:py-4'>
          <label htmlFor='preferences' className='go-label-16'>
            Preferences
          </label>
          <textarea
            id='preferences'
            className='form-textarea go-inputtext-16'
            rows='4'
            required
            value={currentPreferences}
            onChange={(e) => setCurrentPreferences(e?.target?.value)}
            onBlur={(e) => setCurrentPreferences(e?.target?.value)}
          ></textarea>
        </div>
      </div>
    </div>
  );
}

export default Services;
