import React, { useEffect, useState } from 'react';
import { dateFn, utcTimeToLocalTime } from '../../../utils/dateTimeFormatter';
import ClientButton from '../../../components/ClientButton';
import DatePicker from 'react-datepicker';
import location from '../../../images/location.png';
import group from '../../../images/group.png';
import 'react-datepicker/dist/react-datepicker.css';
import ProviderProfile from '../../../components/ProviderProfile';
import { isEmpty } from 'lodash';
import moment from 'moment';
import AccordionBasic from '../../../components/AccordionBasic';
import AccordionProviderTimes from '../../../components/AccordionProviderTimes';

export default function SingleProvider({ provider, onSelectProvider, orderProvider }) {
  const [availableTimes, setAvailableTimes] = useState([]);
  const convertDateTimeFormatFn = (schedules) => {
    const schedulesArray = Object.entries(schedules || {}).map(([key, value]) => {
      let temp = {
        date: dateFn('type6', key),
        times: value.map((item) => {
          return {
            start_time: utcTimeToLocalTime(`${key}T${item?.start_time}`),
            end_time: utcTimeToLocalTime(`${key}T${item?.end_time}`),
          };
        }),
      };
      return temp;
    });
    setAvailableTimes(schedulesArray);
  };
  useEffect(() => {
    convertDateTimeFormatFn(provider?.schedules);
  }, [provider?.schedules]);

  const renderAvailableTimes = () => (
    <div className='mt-2 w-full'>
      <div className='max-w-2xl overflow-y-auto'>
        {!isEmpty(availableTimes) ? (
          availableTimes?.map((item, idx) => (
            <AccordionProviderTimes key={idx} title={item?.date}>
              {item?.times?.map((time, i) => (
                <p className='py-1' key={i}>
                  {`${time?.start_time} - ${time?.end_time}`}
                </p>
              ))}
            </AccordionProviderTimes>
          ))
        ) : (
          <span className='go-textblack-14 py-1'>No Available Times</span>
        )}
      </div>
    </div>
  );

  const renderProvider = () => (
    <div
      className={` min-w-72 relative mr-4 rounded-lg border-2 bg-white pt-12 shadow-xl ${
        provider?.id == orderProvider?.id ? 'border-client-pickupcomplete' : 'border-white'
      }`}
      onClick={() => onSelectProvider(provider)}
    >
      {provider?.isPreferred && (
        <span className='bg-client-pickupcomplete absolute -top-0 rounded-tl-lg p-2 px-3 text-xs text-white'>
          Preferred Provider
        </span>
      )}
      <img className='absolute top-0 right-0 m-2 w-10' src={location} alt='' />
      <ProviderProfile provider={provider} type='multiple' readonly={true} />
      <div className=' py-3 px-4'>
        <div className='go-textblack-15 text-client-300 mb-1 text-center'>Available Pickup Times</div>
        {renderAvailableTimes()}
      </div>
    </div>
  );

  const renderAnyProvider = () => (
    <div
      className={` min-w-72 relative mr-4 rounded-lg border bg-white pt-12 shadow-xl ${
        orderProvider == null ? 'border-client-pickupcomplete' : 'border-white'
      }`}
      onClick={() => onSelectProvider(null)}
    >
      <div className={'mb-2 min-h-[180px] flex-row items-center'}>
        <div className={'flex w-full justify-center'}>
          <img className={' mb-4 h-[80px] w-[80px] rounded-full bg-[#f3f8ff] p-2'} src={group} alt={'Any Provider'} />
        </div>
        <div className={'w-full text-center'}>
          <div className='go-blue-20 mb-1'>Auto-Match Me</div>
          <div className='go-textblack-15 my-6'>
            We'll match you with an <br />
            available Provider
          </div>
        </div>
      </div>
    </div>
  );

  return <div>{isEmpty(provider) ? renderAnyProvider() : renderProvider()}</div>;
}
