import React, { useEffect, useState } from 'react';
import DropdownSingleAddress from '../../components/DropdownSingleAddress';
import { useSelector } from 'react-redux';
import lc_bg from '../../images/lc_empty_promotions.png';
import pl_bg from '../../images/pl_empty_promotions.png';
import { getPromotionsApi } from '../../redux/Reducers/commonApis';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import PromoCard from '../../components/PromoCard';
import { toast } from 'react-hot-toast';
import NoDataHtml from '../../components/NoDataHtml';
import PromotionCard from '../../components/PromotionCard';

const tenantName = import.meta.env.VITE_TENANT_NAME;
export default function Promotions() {
  const { allServiceAddresses, selectedAddress } = useSelector((state) => state.serviceAddresses);
  const [allLocations, setAllLocations] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (allServiceAddresses) {
      const locations = allServiceAddresses.map((address) => {
        return {
          id: address.id,
          value: `${address?.name}, ${address?.address_line_1}`,
        };
      });
      setAllLocations(locations);
    }
  }, [allServiceAddresses]);
  const getPromotionsFn = (id) => {
    setLoading(true);
    const response = getPromotionsApi(id);
    response.then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(res?.message || 'Something went wrong');
      }
    });
  };
  const onPromoClickFn = (id) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        return { ...item, applyBtn: !item.applyBtn };
      }
      return item;
    });
    // setdata(newData);
  };
  useEffect(() => {
    getPromotionsFn(selectedAddress?.id);
  }, [selectedAddress]);
  return (
    <div>
      <div className='relative flex items-center'>
        <div className='go-heading-18 font-medium'>
          Service Address -
          <DropdownSingleAddress dropdownValues={allLocations} newItem={false} />
        </div>
      </div>
      <div className='mt-4'>
        {loading ? (
          <Loader text='loading promotions...' classes='h-96' />
        ) : data?.length == 0 || data == null ? (
          <NoDataHtml>
            <div className='max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8'>
              <div className=' text-center'>
                There are no promotional offers for your location at this time. But don’t worry, we add new promos
                often!
              </div>
              <div className='m-auto  mt-5 max-w-3xl rounded-md border border-slate-300 bg-white p-5'>
                <div className='grid grid-cols-2 content-center gap-10 '>
                  <p className='  sm:m-10 sm:text-2xl'>CHECK YOUR INBOX FOR NEW PROMOTIONS ALERTS!</p>
                  {tenantName === 'laundrycare' ? <img src={lc_bg} alt='' /> : <img src={pl_bg} alt='' />}
                </div>
              </div>
            </div>
          </NoDataHtml>
        ) : (
          <div className='grid grid-cols-12 gap-6'>
            {data?.map((promotion) => (
              <div className='col-span-full flex flex-col rounded-sm sm:col-span-6' key={promotion?.id}>
                <PromotionCard promo={promotion} onPromoClick={() => onPromoClickFn} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
