import axios from 'axios';
import { GET_ALL_PROVIDERS, GET_ALL_PROVIDERS_LOADING, SELECTED_PROVIDERS } from '../ActionsTypes';
import { getRequest, postRequest, getPostRequest, putRequest } from '../../utils/axios';
import { setAllServiceAddressesAction, setAllServiceAddressesLoadingAction } from '../Actions/ServiceAddressesActions';
import toast from 'react-hot-toast';
import { setAllProvidersAction, setAllProvidersLoadingAction } from '../Actions/ProvidersActions';
const initialState = {
  allProviders: [],
  allProvidersLoading: false,
  selectedProvider: null,
};
export const ProvidersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ALL_PROVIDERS:
      return { ...state, allProviders: payload };
    case GET_ALL_PROVIDERS_LOADING:
      return { ...state, allProvidersLoading: payload };
    case SELECTED_PROVIDERS:
      return { ...state, selectedProvider: payload };
    default:
      return state;
  }
};

export const getAllProvidersApi =
  (id, payload = {}) =>
  async (dispatch) => {
    try {
      dispatch(setAllProvidersLoadingAction(true));
      const response_data = await getPostRequest(`/accounts/client/preferred_providers/${id}`, payload);
      dispatch(setAllProvidersAction(response_data?.data?.data));
      dispatch(setAllProvidersLoadingAction(false));
    } catch (error) {
      dispatch(setAllProvidersLoadingAction(false));
      toast.error(error?.response?.data?.error?.message || 'Something went wrong');
      return error?.response?.data;
    }
  };
