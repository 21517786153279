import React, { useEffect, useState } from 'react';
import pl_onetime from '../../../images/pl_onetime.png';
import pl_subscription from '../../../images/pl_subscription.png';
import lc_onetime from '../../../images/lc_onetime.png';
import lc_subscription from '../../../images/lc_subscription.png';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderTypeAction } from '../../../redux/Actions/CreateOrderActions';
import { resetCreateOrderFlow } from '../../../redux/Reducers/CreateOrderReducer';

export default function OrderType() {
  const dispatch = useDispatch();
  const { orderType } = useSelector((state) => state.createNewOrder);
  const setOrderType = (type) => {
    dispatch(resetCreateOrderFlow());
    dispatch(setOrderTypeAction(type));
  };
  useEffect(() => {
    dispatch(resetCreateOrderFlow());
  }, []);

  return (
    <div>
      <div className='mt-10 grid grid-cols-12 gap-6'>
        <div
          className={
            ' col-span-full cursor-pointer rounded-lg border-2 bg-white xl:col-span-5' +
            (orderType === 'oneTime' ? ' border-client-link ' : ' border-white')
          }
          onClick={() => setOrderType('oneTime')}
        >
          <div className='flex h-52 flex-col p-5'>
            <div className='py-2'>
              <header className='flex items-start'>
                {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
                  <img className='mx-5' src={lc_onetime} width='70' height='70' />
                ) : (
                  <img className='mx-5' src={pl_onetime} width='70' height='70' />
                )}
                <div className='grow'>
                  <div className='mb-0.5 items-start justify-between sm:flex'>
                    <div className='mb-2 items-center sm:mb-0 xl:flex'>
                      <h3 className='go-heading-20'>One time</h3>
                    </div>
                  </div>
                  <div className='go-label-16'>A one time pickup</div>
                </div>
              </header>
            </div>
          </div>
        </div>
        <div
          className={
            ' col-span-full cursor-pointer rounded-lg border-2  bg-white  xl:col-span-5' +
            (orderType === 'subscription' ? ' border-client-link ' : ' border-white')
          }
          onClick={() => setOrderType('subscription')}
        >
          <div className='flex h-52 flex-col p-5'>
            <div className='py-2'>
              <header className='flex items-start'>
                {import.meta.env.VITE_TENANT_NAME === 'laundrycare' ? (
                  <img className='mx-5' src={lc_subscription} width='70' height='70' />
                ) : (
                  <img className='mx-5' src={pl_subscription} width='70' height='70' />
                )}
                <div className='grow'>
                  <div className='mb-0.5 items-start justify-between sm:flex'>
                    <div className='mb-2 items-center sm:mb-0 xl:flex'>
                      <h3 className='go-heading-20'>Subscription</h3>
                    </div>
                  </div>
                  <div className='go-label-16'>A recurring order</div>
                  <ul className='my-3 mr-3 block   md:mr-0 md:block'>
                    {['Automatic repeat pickups', 'Priority schedule options', 'Pause or cancel anytime'].map(
                      (item, idx) => (
                        <li className='mr-0.5 block md:mr-0 md:mb-0.5' key={idx}>
                          <a className='flex items-center whitespace-nowrap rounded py-0.5' href='#0'>
                            <svg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'>
                              <g fill='#28C2B3' fillRule='nonzero'>
                                <path d='M7.282 8.467a.753.753 0 1 0-1.064 1.066l2.25 2.25A.75.75 0 0 0 9 12a.75.75 0 0 0 .54-.255l5.25-6a.75.75 0 0 0-1.125-.99L9 10.155 7.282 8.467z' />
                                <path d='M15.75 8.25A.75.75 0 0 0 15 9a6 6 0 0 1-6 6A6 6 0 0 1 4.747 4.77 5.947 5.947 0 0 1 9 3c.48.003.957.058 1.425.165a.75.75 0 1 0 .352-1.455A7.905 7.905 0 0 0 9 1.5a7.5 7.5 0 0 0-5.25 12.817A7.447 7.447 0 0 0 9 16.5 7.5 7.5 0 0 0 16.5 9a.75.75 0 0 0-.75-.75z' />
                              </g>
                            </svg>
                            <span className='go-label-16 ml-2'>{item} </span>
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
