import React, { useCallback } from 'react';
import ClientButton from '../../components/ClientButton';

export default function InvoiceFooter({ open, setOpen, billingDetails }) {
  const openPayInvoice = useCallback((e, val) => {
    e.stopPropagation();
    setOpen(val);
  }, []);
  return (
    <div className=' mt-10 w-full rounded-lg bg-white  '>
      {billingDetails?.addresses[0]?.billing_details?.default_payment_method_type == 'CHECK' ? (
        <div className='flex items-center justify-center p-3'>
          <span className=' go-textblacknormal-14'>
            Check - (<span className=' font-bold'>mail to: </span>PO Box 693 Centerburg, OH 43011){' '}
          </span>
        </div>
      ) : billingDetails?.addresses[0]?.billing_details?.default_payment_method_type == 'ACH' ? (
        <></>
      ) : (
        <div className='flex items-center justify-end p-3'>
          <ClientButton
            classes=''
            text='Pay Invoice'
            variant='primary'
            onClick={(e) => {
              openPayInvoice(e, true);
            }}
          />
        </div>
      )}
    </div>
  );
}
